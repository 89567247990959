import React from "react";
import { graphql } from "gatsby";
import useTitleContext from "../../context/useTitleContext";
import Link from "../../components/Navigation/Link";
import "./Vita.scss";

function Vita(data) {
  const node = data.data.allMarkdownRemark.edges[0].node;
  const html = node.html;
  useTitleContext("");

  const isBiografie = node.fields.slug === "/biografie/";

  return (
    <>
      <div className="chooser">
        <Link
          to="/biografie"
          activeClassName="chooser--active"
          className={"chooser__top"}
        >
          Biografie
        </Link>
        <Link
          to="/publikationen"
          activeClassName="chooser--active"
          className={"chooser__top"}
        >
          Publikationen
        </Link>
      </div>

      <div className="container">
        <article dangerouslySetInnerHTML={{ __html: html }}></article>
      </div>
      <div className="chooser--bottom">
        <Link
          to={isBiografie ? "/publikationen" : "/biografie"}
          className={"chooser__bottom"}
        >
          {isBiografie ? "Publikationen" : "Biografie"}
        </Link>
      </div>
    </>
  );
}

export const query = graphql`
  query VITA($slug: String) {
    allMarkdownRemark(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          fields {
            slug
          }
          html
        }
      }
    }
  }
`;
export default Vita;
